<template>
    <section class="bg-stone-900">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div class="w-full rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-stone-800 border-stone-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 class="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                        Sign in to your account
                    </h1>

                    <div v-if="errorMessage" class="p-4 mb-4 text-sm text-red-400 bg-stone-900 rounded-lg" role="alert">
                        <span class="font-medium">{{ errorMessage }}</span>
                    </div>

                    <form class="space-y-4 md:space-y-6" @submit.prevent="submitLogin">
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-white">Your email</label>
                            <input
                                type="email"
                                v-model="email"
                                id="email"
                                class="border sm:text-sm rounded-lg block w-full p-2.5 bg-stone-700 border-stone-600 placeholder-stone-400   text-white focus:ring-blue-500 focus:border-blue-500"
                                placeholder="email"
                                required
                            />
                        </div>
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-white">Password</label>
                            <input
                                type="password"
                                v-model="password"
                                id="password"
                                class="border sm:text-sm rounded-lg block w-full p-2.5 bg-stone-700 border-stone-600 placeholder-stone-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                placeholder="••••••••"
                                required
                            />
                        </div>
                        <div class="flex items-center justify-between">
                            <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input
                                        id="remember"
                                        aria-describedby="remember"
                                        type="checkbox"
                                        class="w-4 h-4 border rounded bg-stone-700 border-stone-600 focus:ring-orange-600 ring-offset-stone-800"
                                    />
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="remember" class="text-stone-300">Remember me</label>
                                </div>
                            </div>
                            <a href="#" class="text-sm font-medium hover:underline text-orange-500">Forgot password?</a>
                        </div>

                        <button type="submit" class="w-full text-white focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-orange-700 hover:bg-orange-800   focus:scale-[.97]">Sign in</button>

                        <p class="text-sm font-light text-gray-500">
                            Don’t have an account yet? <a href="javascript:" class="font-medium text-orange-600 hover:underline">Sign up</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import { ref } from 'vue'
    import Http from '@/http'

    const email = ref('test@test.com')
    const password = ref('test')
    const errorMessage = ref<string|null>(null)

    function submitLogin() {
        errorMessage.value = null

        Http.post('/login', {
            data: {
                email: email.value,
                password: password.value,
            },
            success: response => {
                if (response.error) {
                    errorMessage.value = response.error

                } else if (response.redirectUrl) {
                    window.location.href = response.redirectUrl
                }
            }
        })
    }

</script>
